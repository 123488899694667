import { SubscriptionStatus } from "src/billinglatest/types";
import {
  FilesOrderBy,
  FoldersOrderBy,
  Scalars,
  ScreenRotation,
  ScreensOrderBy,
} from "../types.g";

export enum Locale {
  en = "en",
  th = "th",
  es = "es",
}

export const languageOptions = [
  {
    key: Locale.en.toString(),
    text: "English",
    value: Locale.en.toString(),
  },
  {
    key: Locale.es.toString(),
    text: "Español",
    value: Locale.es.toString(),
  },
];

/* temporary hide */
// const localeOptions = [
//   {
//     key: '0',
//     text: 'US English (August 19 2019, 4:55 PM)',
//     value: 'en-US',
//   },
//   {
//     key: '1',
//     text: 'GB English (19 August 2019, 16:55)',
//     value: 'en-GB',
//   },
// ]

export enum View {
  List = "list",
  Grid = "grid",
}

export enum ReactAppEnvironment {
  Production = "production",
  Development = "development",
  Staging = "staging",
}

export enum Orientation {
  Landscape = "landscape",
  Portrait = "portrait",
}

export const urls = {
  CONTACT_SALES: "https://get.screencloud.com/feature-request",
};

export enum features {
  DASHBOARDS = "dashboards",
  PERMISSIONS = "permissions",
  AUDIT_LOGS = "audit_logs",
  SSO = "SSO",
  EMBED_CHANNEL = "embed_channel",
  SPACE_BILLING = "space_billing",
  CUSTOM_ROLES = "custom_roles",
}

export const ZoneLayoutNames = {
  // use lower case because it must check the name of icon from zone layout
  common_layout_landscape_1_zone_type_a: {
    common_name: "common_layout_landscape_1_zone_type_a",
    title: "Landscape",
  },
  common_layout_landscape_2_zone_type_a: {
    common_name: "common_layout_landscape_2_zone_type_a",
    title: "Main + Left Bar",
  },
  common_layout_landscape_2_zone_type_b: {
    common_name: "common_layout_landscape_2_zone_type_b",
    title: "Main + Right Bar",
  },
  common_layout_landscape_2_zone_type_c: {
    common_name: "common_layout_landscape_2_zone_type_c",
    title: "Split View",
  },
  common_layout_landscape_2_zone_type_d: {
    common_name: "common_layout_landscape_2_zone_type_d",
    title: "Main + Footer",
  },
  common_layout_landscape_3_zone_type_a: {
    common_name: "common_layout_landscape_3_zone_type_a",
    title: "Main + Left Bar & Footer",
  },
  common_layout_landscape_3_zone_type_b: {
    common_name: "common_layout_landscape_3_zone_type_b",
    title: "Main + Left Bar & Header",
  },
  common_layout_landscape_3_zone_type_c: {
    common_name: "common_layout_landscape_3_zone_type_c",
    title: "Main + Right Bar & Footer",
  },
  common_layout_landscape_3_zone_type_d: {
    common_name: "common_layout_landscape_3_zone_type_d",
    title: "Main + Right Bar & Header",
  },
  common_layout_landscape_3_zone_type_e: {
    common_name: "common_layout_landscape_3_zone_type_e",
    title: "Triple View",
  },

  common_layout_landscape_4_zone_type_a: {
    common_name: "common_layout_landscape_4_zone_type_a",
    title: "Main + Left Triple Bar",
  },
  common_layout_landscape_4_zone_type_b: {
    common_name: "common_layout_landscape_4_zone_type_b",
    title: "Main + Right Triple Bar",
  },

  common_layout_landscape_5_zone_type_a: {
    common_name: "common_layout_landscape_5_zone_type_a",
    title: "Main + Left Triple Bar & Footer",
  },
  common_layout_landscape_5_zone_type_b: {
    common_name: "common_layout_landscape_5_zone_type_b",
    title: "Main + Left Triple Bar & Header",
  },
  common_layout_landscape_5_zone_type_c: {
    common_name: "common_layout_landscape_5_zone_type_c",
    title: "Main + Right Triple Bar & Footer",
  },
  common_layout_landscape_5_zone_type_d: {
    common_name: "common_layout_landscape_5_zone_type_d",
    title: "Main + Right Triple Bar & Header",
  },

  common_layout_portrait_1_zone_type_a: {
    common_name: "common_layout_portrait_1_zone_type_a",
    title: "Portrait",
  },
  common_layout_portrait_2_zone_type_a: {
    common_name: "common_layout_portrait_2_zone_type_a",
    title: "Main + Lower Pane",
  },
  common_layout_portrait_2_zone_type_b: {
    common_name: "common_layout_portrait_2_zone_type_b",
    title: "Split View",
  },
  common_layout_portrait_2_zone_type_c: {
    common_name: "common_layout_portrait_2_zone_type_c",
    title: "Main + Upper Pane",
  },
  common_layout_portrait_3_zone_type_a: {
    common_name: "common_layout_portrait_3_zone_type_a",
    title: "Main + Lower Pane & Footer",
  },
  common_layout_portrait_3_zone_type_b: {
    common_name: "common_layout_portrait_3_zone_type_b",
    title: "Main + Lower Pane & Header",
  },
  common_layout_portrait_3_zone_type_c: {
    common_name: "common_layout_portrait_3_zone_type_c",
    title: "Triple View",
  },
  common_layout_portrait_3_zone_type_d: {
    common_name: "common_layout_portrait_3_zone_type_d",
    title: "Main + Upper Pane & Footer",
  },
  common_layout_portrait_3_zone_type_e: {
    common_name: "common_layout_portrait_3_zone_type_e",
    title: "Main + Upper Pane & Header",
  },
};

export const VanguardZoneLayoutNames = {
  common_layout_vanguard_landscape_2_zone_type_a: {
    common_name: "common_layout_vanguard_landscape_2_zone_type_a",
    title: "1 Main Zone",
  },
  common_layout_vanguard_landscape_3_zone_type_a: {
    common_name: "common_layout_vanguard_landscape_3_zone_type_a",
    title: "1 Main Zone + Ticker",
  },
  common_layout_vanguard_landscape_3_zone_type_b: {
    common_name: "common_layout_vanguard_landscape_3_zone_type_b",
    title: "2 Zones layout",
  },
  common_layout_vanguard_landscape_3_zone_type_c: {
    common_name: "common_layout_vanguard_landscape_3_zone_type_c",
    title: "Video Frame",
  },
  common_layout_vanguard_landscape_4_zone_type_a: {
    common_name: "common_layout_vanguard_landscape_4_zone_type_a",
    title: "3 Zones layout",
  },
  common_layout_vanguard_landscape_5_zone_type_a: {
    common_name: "common_layout_vanguard_landscape_5_zone_type_a",
    title: "4 Zones layout",
  },
  common_layout_vanguard_landscape_5_zone_type_b: {
    common_name: "common_layout_vanguard_landscape_5_zone_type_b",
    title: "5 Zones layout",
  },
  common_layout_vanguard_landscape_5_zone_type_c: {
    common_name: "common_layout_vanguard_landscape_5_zone_type_c",
    title: "3 x Portraits",
  },
  common_layout_vanguard_landscape_5_zone_type_d: {
    common_name: "common_layout_vanguard_landscape_5_zone_type_d",
    title: "5 Zones layout + Background",
  },
  common_layout_vanguard_portrait_2_zone_type_a: {
    common_name: "common_layout_vanguard_portrait_2_zone_type_a",
    title: "1 Main Zone",
  },
  common_layout_vanguard_portrait_3_zone_type_a: {
    common_name: "common_layout_vanguard_portrait_3_zone_type_a",
    title: "1 Main Zone + Ticker",
  },
  common_layout_vanguard_portrait_5_zone_type_a: {
    common_name: "common_layout_vanguard_portrait_5_zone_type_a",
    title: "4 Zones layout",
  },
  common_layout_vanguard_portrait_7_zone_type_a: {
    common_name: "common_layout_vanguard_portrait_7_zone_type_a",
    title: "Stacked",
  },
};

export const BLANK_ZONES_LAYOUT_ID: string =
  "00000000-0000-0000-0000-000000000000";

export const DUMMY_APP_INSTANCE_ID = "00000000-0000-0000-0000-000000000000";

export enum AppContext {
  CHANNEL = "CHANNEL",
  PLAYLIST = "PLAYLIST",
  SCREEN = "SCREEN",
  SIGNIN = "SIGNIN",
  SIGNUP = "SIGNUP",
}

export enum Orderby {
  RECENT = "CREATED_AT_DESC",
  ASC = "NAME_ASC",
  DESC = "NAME_DESC",
}

export enum SortingActions {
  SORT_BY_NAME = "SORT_BY_NAME",
  SORT_BY_STARRED = "SORT_BY_STARRED",
  SORT_BY_DATE = "SORT_BY_DATE",
  SORT_BY_TAG = "SORT_BY_TAG",
}

export const MediaOrderBy = {
  FILE_CREATED_AT_DESC: FilesOrderBy.CreatedAtDesc,
  FOLDER_CREATED_AT_DESC: FoldersOrderBy.CreatedAtDesc,
};

export const DEFAULT_GLOBAL_DURATION: number = 10000;

export const DEFAULT_GLOBAL_DURATIONS: Scalars["JSON"] = {
  app: 60000,
  image: 10000,
  link: 60000,
  site: 60000,
  document: 10000,
};

export enum LinkValidationErrorCode {
  IFRAME_DENIED = "IFRAME_DENIED",
  INVALID_URL = "INVALID_URL",
  URL_UNAVAILABLE = "URL_UNAVAILABLE",
  BAD_PAGE_RESPONSE = "BAD_PAGE_RESPONSE",
  INTERNAL_ERR = "INTERNAL_ERR",
  CLOUD_ERR = "CLOUD_ERR",
}

export enum LinkCloudServiceStatusCode {
  READY = "Ready",
  FAILED = "Failed",
}

export const DocumentAllowedMimetypes: string[] = [
  "application/pdf",
  "application/msword",
  "application/illustrator",
  "application/postscript",
  "application/photoshop",
  "application/psd",
  "application/x-photoshop",
  "application/rtf",
  "application/x-rtf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/x-iwork-pages-sffpages",
  "application/x-iwork-keynote-sffkey",
  "application/x-iwork-numbers-sffnumbers",
];

export const FilestackAllowedMimetypes: string[] = [
  "image/*",
  "video/*",
  "text/*",
  "audio/mp3",
  ...DocumentAllowedMimetypes,
];

export const FilestackAllowedFileExtensions: string[] = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".tif",
  ".tiff",
  ".webm",
  ".mpg",
  ".mp2",
  ".mpeg",
  ".mpe",
  ".mp4",
  ".m4p",
  ".m4v",
  ".avi",
  ".mov",
  ".wmv",
  ".qt",
  ".pdf",
  ".ods",
  ".xlsx",
  ".xls",
  ".doc",
  ".docx",
  ".odt",
  ".rtf",
  ".wpd",
  ".ppt",
  ".pptx",
  ".pages",
  ".numbers",
  ".key",
  ".xml",
  ".xlsb",
  ".ttf",
  ".otf",
  ".pfr",
  ".woff",
  ".woff2",
  ".bdf",
  ".gsf",
  ".psf",
  ".pcf",
  ".snf",
  ".pfa",
  ".pfb",
  ".pfm",
  ".afm",
];

export const FilestackAllowedFileExtensionsWithAudio: string[] = [
  ...FilestackAllowedFileExtensions,
  ...[".mp3", ".wav", ".ogg"],
];
export const FileStackSource = [
  "local_file_system",
  "unsplash",
  "url",
  "googledrive",
  "dropbox",
  "onedrive",
  "box",
];

export enum Device {
  UHD_4K = "UHD_4K",
  QHD = "QHD",
  FULL_HD_1080P = "FULL_HD_1080P",
  HD_720P = "HD_720P",
  SD_480P = "SD_480P",
  IPAD = "IPAD",
  IPHONE_X = "IPHONE_X",
  CUSTOM = "CUSTOM",
}

export const PreviewDeviceList: {
  key: Device;
  text: string;
  value: string;
}[] = [
  { key: Device.UHD_4K, text: "UHD 4K", value: Device.UHD_4K },
  { key: Device.QHD, text: "QHD", value: Device.QHD },
  { key: Device.FULL_HD_1080P, text: "Full HD", value: Device.FULL_HD_1080P },
  { key: Device.HD_720P, text: "720p HD", value: Device.HD_720P },
  { key: Device.SD_480P, text: "480p SD", value: Device.SD_480P },
  { key: Device.IPAD, text: "iPad", value: Device.IPAD },
  { key: Device.IPHONE_X, text: "iPhone X", value: Device.IPHONE_X },
  { key: Device.CUSTOM, text: "Custom Size", value: Device.CUSTOM },
];

export enum MediaType {
  IMAGE = "IMAGE",
  DOCUMENT = "DOCUMENT",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  UNKNOWN = "UNKNOWN",
}

export enum ListItemType {
  IMAGE = "IMAGE",
  DOCUMENT = "DOCUMENT",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  APP = "APP",
  LINK = "LINK",
  SITE = "SITE",
  PLAYLIST = "PLAYLIST",
}

export const NotificationMessage: Record<string, string> = {
  LIVE: "was reactivated successfully",
  PAUSED: "was deactivated successfully",
};

export enum Colors {
  WHITE = "#FFFFFF",
  BLACK = "#000000",
}

export enum PERMISSION_SECTION {
  ORG = "org",
  BILLING = "billing",
  LOG = "log",
  PERMISSION_SET = "permission_set",
  USER = "user",
  GROUP = "group",
  THEME = "theme",
  BOT = "bot",
  BOT_TOKEN = "bot_token",
  TOKEN = "token",
  SPACE = "space",
  CHANNEL = "channel",
  PLAYLIST = "playlist",
  MEDIA = "media",
  APP = "app",
  APP_INSTANCE = "app_instance",
  LINK = "link",
  SCREEN = "screen",
  SITE = "site",
}

export enum PERMISSION_ACTION {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
  READ = "read",
  SHARE = "share",
  INSTALL = "install",
  UNINSTALL = "uninstall",
  SCHEDULE = "schedule",
  PUBLISH = "publish",
  CAST = "cast",
  LOG = "log",
  CONTENT = "content",
  GROUPS = "groups",
  PERMISSIONS = "permissions",
  MEMBERS = "members",
  INVITE = "invite",
  MANAGE = "manage",
  IMPORT = "import",
  AUDIT = "audit",
  FEATURES = "features",
  BRAND = "brand",
  TRANSFER = "transfer",
}

export enum Typenames {
  AppInstance = "AppInstance",
  ScreensOptions = "ScreensOptions",
  MediaOrder = "MediaOrder",
  Screen = "Screen",
  ScreenGroupsConnection = "ScreenGroupsConnection",
  UpdateScreenByIdPayload = "UpdateScreenByIdPayload",
  UpdateScreenByIdMutation = "UpdateScreenByIdMutation",
  UpdateScreenListByScreenIdMutation = "UpdateScreenListByScreenIdMutation",
  UpdateScreenSettingsMutation = "UpdateScreenSettingsMutation",
  DepairScreenPayload = "DepairScreenPayload",
  File = "File",
  FileOutput = "FileOutput",
  UpdateFileById = "UpdateFileById",
  UpdateFileByIdMutation = "UpdateFileByIdMutation",
  UpdateFileByIdPayload = "UpdateFileByIdPayload",
  UpdateFileAvailablePayload = "UpdateFileAvailablePayload",
  UpdateFileExpirePayload = "UpdateFileExpirePayload",
  DeleteFileByIdPayload = "DeleteFileByIdPayload",
  DeleteFileByIdsPayload = "DeleteFileByIdsPayload",
  Folder = "Folder",
  FolderHierarchy = "FolderHierarchy",
  UpdateFolderByIdPayload = "UpdateFolderByIdPayload",
  DeleteFolderByIdPayload = "DeleteFolderByIdPayload",
  DeleteFolderByIdsPayload = "DeleteFolderByIdsPayload",
  CreateFolder = "CreateFolder",
  CreateFolderMutation = "CreateFolderMutation",
  CreateFolderPayload = "CreateFolderPayload",
  CreateScreenGroupPayload = "CreateScreenGroupPayload",
  ScreenGroup = "ScreenGroup",
  ScreensConnection = "ScreensConnection",
  DeleteScreenGroupByIdPayload = "DeleteScreenGroupByIdPayload",
  UpdateFolderById = "UpdateFolderById",
  UpdateFolderByIdMutation = "UpdateFolderByIdMutation",
  CastStartMutation = "CastStartMutation",
  CastStartPayload = "CastStartPayload",
  Cast = "Cast",
  ScreenCastStopPayload = "ScreenCastStopPayload",
  Playlist = "Playlist",
  PlaylistsConnection = "PlaylistsConnection",
  DuplicatePlaylistPayload = "DuplicatePlaylistPayload",
  UpdatePlaylistPayload = "UpdatePlaylistPayload",
  UpdatePlaylistTagsPayload = "UpdatePlaylistTagsPayload",
  DeletePlaylistPayload = "DeletePlaylistPayload",
  RevertDraftPlaylistPayload = "RevertDraftPlaylistPayload",
  Favorite = "Favorite",
  AddFavoritePlaylistPayload = "AddFavoritePlaylistPayload",
  RemoveFavoritePlaylistPayload = "RemoveFavoritePlaylistPayload",
  SetScreenChannelPayload = "SetScreenChannelPayload",
  Channel = "Channel",
  DeleteChannelPayload = "DeleteChannelPayload",
  DuplicateChannelPayload = "DuplicateChannelPayload",
  UpdateChannelPayload = "UpdateChannelPayload",
  UpdateChannelNamePayload = "UpdateChannelNamePayload",
  UpdateChannelCoverPayload = "UpdateChannelCoverPayload",
  RevertDraftChannelPayload = "RevertDraftChannelPayload",
  AddFavoriteFilePayload = "AddFavoriteFilePayload",
  RemoveFavoriteFilePayload = "RemoveFavoriteFilePayload",
  AddFavoriteFolderPayload = "AddFavoriteFolderPayload",
  RemoveFavoriteFolderPayload = "RemoveFavoriteFolderPayload",
  AddFavoriteLinkPayload = "AddFavoriteLinkPayload",
  RemoveFavoriteLinkPayload = "RemoveFavoriteLinkPayload",
  Mutation = "Mutation",
  Link = "Link",
  Site = "Site",
  UpdateLinkByIdPayload = "UpdateLinkByIdPayload",
  FilesConnection = "FilesConnection",
  FoldersConnection = "FoldersConnection",
  Space = "Space",
  SpacesConnection = "SpacesConnection",
}

export interface Permission {
  read: boolean;
  create: boolean;
  delete: boolean;
  update: boolean;
  share?: boolean;
  favorite?: boolean;
  deactivate?: boolean;
  regenerate?: boolean;
  cast?: boolean;
  invite?: boolean;
  publish?: boolean;
  log?: boolean;
  spaces?: boolean;
  users?: boolean;
  account?: boolean;
  billing?: boolean;
  developer?: boolean;
  organization?: boolean;
  "*"?: boolean;
}

export interface Permissions {
  [key: string]: Permission;
}

export enum RefType {
  LINK = "link",
  SITE = "site",
  CANVAS = "canvas-brush",
  APP = "app",
  APP_INSTANCE = "app_instance",
  CHANNEL = "channel",
  PLAYLIST = "playlist",
  FILE = "file",
  DOCUMENT = "document",
  RECENT = "recent",
  HREF = "href",
}

export declare const SUNDAY = "sun";
export declare const MONDAY = "mon";
export declare const TUESDAY = "tue";
export declare const WEDNESDAY = "wed";
export declare const THURSDAY = "thu";
export declare const FRIDAY = "fri";
export declare const SATURDAY = "sat";
export declare const WEEK_DAYS: string[];
export interface ScheduleRules {
  time?: {
    start: string;
    end: string;
  }[];
  day_of_week?: {
    [SUNDAY]: boolean;
    [MONDAY]: boolean;
    [TUESDAY]: boolean;
    [WEDNESDAY]: boolean;
    [THURSDAY]: boolean;
    [FRIDAY]: boolean;
    [SATURDAY]: boolean;
  };
  date?: {
    start: string | undefined;
    end: string | undefined;
  }[];
  exclusive?: boolean;
  full_screen?: boolean;
  primary_audio?: boolean;
  specific_date?: boolean;
}

export interface DeviceSettings {
  name: string;
  orientation: string;
  rotation?: ScreenRotation;
  updatedAt?: number;
}

export interface ScreenDevice {
  deviceSystemTimezone?: string;
  name: string;
  deviceModel: string;
  devicePlatform: string;
  id: UUID;
  player_height: number;
  player_width: number;
  overrideTimezone?: string;
  screenId: UUID;
  settings: DeviceSettings;
}

export interface ScreenContent extends ContentAdditionalData {
  href?: string;
  _ref?: {
    id: string;
    type: RefType;
  };
  list?: ListContentItem[];
}
export interface ChannelContent extends ContentAdditionalData {
  zones: {
    hidden?: ChannelZone[];
  };
}
export interface ChannelZone extends ChannelZoneContent {}
export interface ChannelZoneContent extends ContentAdditionalData {
  list?: ChannelListContentItem[];
}

export type ChannelListContentItemRefType =
  | RefType.LINK
  | RefType.SITE
  | RefType.FILE
  | RefType.APP
  | RefType.APP_INSTANCE
  | RefType.PLAYLIST;
export interface ChannelListContentItem extends WithRules {
  list_id: string;
  content: {
    href?: string;
    _ref?: {
      id: string;
      type: ChannelListContentItemRefType;
    };
    meta?: any;
    props?: any;
  };
  is_read_only?: boolean;
}
export interface ListContent extends ContentAdditionalData {
  list: ListContentItem[];
  props: any;
}
export interface StandaloneMediaContent extends ContentAdditionalData {
  href?: string;
  _ref?: {
    id: string;
    type:
      | RefType.LINK
      | RefType.SITE
      | RefType.FILE
      | RefType.APP
      | RefType.PLAYLIST;
  };
}
export interface ListContentItem extends Partial<WithRules> {
  __typename?: string;
  list_id: string;
  content: StandaloneMediaContent & {
    _ref: {
      id: string;
      type: RefType;
    };
    props?: any;
  };
}
export interface ListContentItemExtended extends ListContentItem {
  defaultDurations?: DefaultDuration;
  parentEntityId?: string;
  parentEntityType?: "PLAYLIST";
  isInvalid?: boolean;
  expireAt?: Scalars["Datetime"];
  availableAt?: Scalars["Datetime"];
}
export interface WithRules {
  rules: ScheduleRules[];
}
export interface LayoutZoneContent extends ContentAdditionalData {
  href?: string;
  list?: ChannelListContentItem[];
  _ref?: {
    id: string;
    type:
      | RefType.LINK
      | RefType.SITE
      | RefType.FILE
      | RefType.APP
      | RefType.PLAYLIST;
  };
}

export declare type UUID = Scalars["UUID"];
export declare type CompactUUID = string;
export declare type ContentMeta = any;
export declare type SizingType = "fit" | "fill";

export interface DefaultDuration {
  app: number;
  link: number;
  site: number;
  image: number;
  document: number;
}

export interface SizingTypeRules {
  document?: SizingType;
  image?: SizingType;
  video?: SizingType;
}
export interface ContentProps {
  duration?: number;
  default_durations?: DefaultDuration;
  cover?: any;
  layout?: {
    landscape: DefaultLayout;
    portrait: DefaultLayout;
  };
  sizing_type?: SizingTypeRules | SizingType;
}
export interface DefaultLayout {
  config: {
    css: string;
  };
  zones: number;
}
export interface ContentAdditionalData {
  meta?: ContentMeta;
  props?: ContentProps;
}

export const NameMaxLengthAllow: number = 79;
export const PG_MAX_SAFE_SMALLINT: number = 32767;
export const PG_MAX_SAFE_INTEGER: number = 2147483647;

export interface LayoutConfig {
  width: number;
  height: number;
  isFlexible: boolean;
  isScalable: boolean;
}

export enum OnBoardingRefType {
  ORG_COMPANY_DATA = "company",
  ORG_ONBOARDING_V1_CONTENT_SETUP = "onboarding_content_setup",
  ORG_ONBOARDING_V1 = "onboarding_v1",
  ORG_ONBOARDING_V1_SKIP = "onboarding_v1_skip",

  ORG_ONBOARDING_V2_INVITE = "onboarding_v2_invite",
  ORG_ONBOARDING_V2 = "onboarding_v2",
  ORG_ONBOARDING_V2_SKIP = "onboarding_v2_skip",
  ORG_ONBOARDING_V2_CONTENT_SETUP = "onboarding_v2_content_setup",

  ORG_INDUSTRY_NAME = "industry_name",
  ORG_INDUSTRY_KEY = "industry_key",
  ORG_COUNTRY_CODE = "country_code",
  TRIALIST_HIDE = "trialist_hide",
  VISITED_SCREEN_PAIRING = "visited_screen_pairing",
  KC_BOT_HIDE = "kc_bot_hide",
  SCREENS_MANAGER_INTRO_HIDE = "screens_manager_intro_hide",

  ORG_ONBOARDING_TOUR_DONE = "onboarding_tour_done",
  ORG_ONBOARDING_TOUR_SKIP = "onboarding_tour_skip",

  IS_WATCHED_TRIAL_VIDEO = "is_watched_trial_video",
}

export const specialPagePaths = Object.freeze({
  guestPages: Object.freeze(["/system/login", "/migrate/"]),
  anonPages: Object.freeze(["/organizations/login"]),
  crossOrgUserPages: Object.freeze(["/system/login"]),
  crossOrgFeaturePages: Object.freeze(["/unsubscribe-notification"]),
});

export const TitlePageSufix = "ScreenCloud";

export const permissionDescription = {
  app: {
    read: {
      id: "permissions.description.app.read",
      defaultMessage: "see list of installed apps",
      required_permissions: ["space:read", "app_instance:read"],
    },
    install: {
      id: "permissions.description.app.install",
      defaultMessage: "install new apps",
      required_permissions: ["space:read", "app:read", "app_instance:read"],
    },
    uninstall: {
      id: "permissions.description.app.uninstall",
      defaultMessage: "delete installed apps",
      required_permissions: ["space:read", "app:read", "app_instance:read"],
    },
  },
  app_access: {
    read: {
      id: "permissions.description.app_access.read",
      defaultMessage: "read access",
      required_permissions: [],
    },
    create: {
      id: "permissions.description.app_access.install",
      defaultMessage: "create access",
      required_permissions: [],
    },
    delete: {
      id: "permissions.description.app_access.uninstall",
      defaultMessage: "delete access",
      required_permissions: [],
    },
    update: {
      id: "permissions.description.app_access.uninstall",
      defaultMessage: "update access",
      required_permissions: [],
    },
  },
  app_instance: {
    read: {
      id: "permissions.description.app_instance.read",
      defaultMessage: "see list of app instances",
    },
    create: {
      id: "permissions.description.app_instance.create",
      defaultMessage: "create new instances of an app",
      required_permissions: ["space:read", "app_instance:read", "app:read"],
    },
    delete: {
      id: "permissions.description.app_instance.delete",
      defaultMessage: "delete app instances",
      required_permissions: ["space:read", "app_instance:read", "app:read"],
    },
    update: {
      id: "permissions.description.app_instance.update",
      defaultMessage: "update existing instances",
      required_permissions: ["space:read", "app_instance:read", "app:read"],
    },
    share: {
      id: "permissions.description.app_instance",
      defaultMessage: "share app instances with other spaces",
      required_permissions: ["space:read", "app_instance:read", "app:read"],
    },
  },
  app_template: {
    read: {
      id: "permissions.description.app_template.read",
      defaultMessage: "see list of app templates",
    },
    create: {
      id: "permissions.description.app_template.create",
      defaultMessage: "create new templates for apps",
    },
    update: {
      id: "permissions.description.app_template.update",
      defaultMessage: "update existing templates",
    },
  },
  billing: {
    read: {
      id: "permissions.description.billing.read",
      defaultMessage: "see billing information",
      required_permissions: ["space:read"],
    },
    manage: {
      id: "permissions.description.billing.manage",
      defaultMessage: "edit payment method, plans, and billing info",
      required_permissions: ["space:read", "billing:read"],
    },
  },
  bot: {
    read: {
      id: "permissions.description.bot.read",
      defaultMessage: "see list of bots",
    },
    create: {
      id: "permissions.description.bot.create",
      defaultMessage: "create new bots",
    },
    delete: {
      id: "permissions.description.bot.delete",
      defaultMessage: "delete bots",
    },
    update: {
      id: "permissions.description.bot.update",
      defaultMessage: "update existing bots",
    },
  },
  bot_token: {
    read: {
      id: "permissions.description.bot_token.read",
      defaultMessage: "see list of created bot tokens",
    },
    create: {
      id: "permissions.description.bot_token.create",
      defaultMessage: "create new bot tokens",
    },
    delete: {
      id: "permissions.description.bot_token.delete",
      defaultMessage: "delete bot tokens",
    },
    update: {
      id: "permissions.description.bot_token.update",
      defaultMessage: "update existing bot tokens",
    },
  },
  channel: {
    read: {
      id: "permissions.description.channel.read",
      defaultMessage: "see list of channels",
      required_permissions: ["space:read"],
    },
    create: {
      id: "permissions.description.channel.create",
      defaultMessage: "create new channels",
      required_permissions: ["space:read", "channel:read"],
    },
    delete: {
      id: "permissions.description.channel.delete",
      defaultMessage: "delete channels",
      required_permissions: ["space:read", "channel:read"],
    },
    update: {
      id: "permissions.description.channel.update",
      defaultMessage: "update channel information and content",
      required_permissions: ["space:read", "channel:read"],
    },
    publish: {
      id: "permissions.description.channel.publish",
      defaultMessage: "publish any new edits made to channels",
      required_permissions: ["space:read", "channel:read"],
    },
    share: {
      id: "permissions.description.channel.share",
      defaultMessage: "share channels with other spaces",
      required_permissions: ["space:read", "channel:read"],
    },
  },
  layout: {
    read: {
      id: "permissions.description.layout.read",
      defaultMessage: "see list of channel layouts",
    },
    create: {
      id: "permissions.description.layout.create",
      defaultMessage: "create custom channel layouts",
      required_permissions: ["layout:read", "channel:read"],
    },
    delete: {
      id: "permissions.description.layout.delete",
      defaultMessage: "delete custom channel layouts",
      required_permissions: ["layout:read", "channel:read"],
    },
    update: {
      id: "permissions.description.layout.update",
      defaultMessage: "edit existing custom layouts",
      required_permissions: ["layout:read", "channel:read"],
    },
  },
  credential: {
    read: {
      id: "permissions.description.credential.read",
      defaultMessage: "see list of created credentials",
    },
    create: {
      id: "permissions.description.credential.create",
      defaultMessage: "create new credentials",
    },
    delete: {
      id: "permissions.description.credential.delete",
      defaultMessage: "delete credentials",
    },
    update: {
      id: "permissions.description.credential.update",
      defaultMessage: "update existing credentials",
    },
  },
  font: {
    read: {
      id: "permissions.description.font.read",
      defaultMessage: "see list of custom fonts",
    },
    create: {
      id: "permissions.description.font.create",
      defaultMessage: "allow uploading of custom fonts",
      required_permissions: ["font:read"],
    },
    delete: {
      id: "permissions.description.font.delete",
      defaultMessage: "allow deletion of custom fonts",
      required_permissions: ["font:read"],
    },
    update: {
      id: "permissions.description.font.update",
      defaultMessage: "allow updating of custom fonts",
      required_permissions: ["font:read"],
    },
  },
  group: {
    read: {
      id: "permissions.description.group.read",
      defaultMessage: "see list of groups in the organization",
    },
    create: {
      id: "permissions.description.group.create",
      defaultMessage: "create new groups",
      required_permissions: ["group:read"],
    },
    delete: {
      id: "permissions.description.group.delete",
      defaultMessage: "delete groups",
      required_permissions: ["group:read"],
    },
    update: {
      id: "permissions.description.group.update",
      defaultMessage: "update groups’ names",
      required_permissions: ["group:read"],
    },
    members: {
      id: "permissions.description.group.members",
      defaultMessage: "add or remove members from groups",
      required_permissions: ["group:read"],
    },
    permissions: {
      id: "permissions.description.group.permissions",
      defaultMessage: "manage groups’ permissions to different spaces",
      required_permissions: ["group:read"],
    },
  },
  link: {
    read: {
      id: "permissions.description.group.read",
      defaultMessage: "see list of created links",
      required_permissions: ["space:read"],
    },
    create: {
      id: "permissions.description.group.create",
      defaultMessage: "create new links",
      required_permissions: ["space:read", "link:read"],
    },
    delete: {
      id: "permissions.description.group.delete",
      defaultMessage: "delete links",
      required_permissions: ["space:read", "link:read"],
    },
    update: {
      id: "permissions.description.group.update",
      defaultMessage: "update existing links",
      required_permissions: ["space:read", "link:read"],
    },
    share: {
      id: "permissions.description.group.share",
      defaultMessage: "share links with other spaces",
      required_permissions: ["space:read", "link:read"],
    },
  },
  media: {
    read: {
      id: "permissions.description.media.read",
      defaultMessage: "see list of uploaded media files",
      required_permissions: ["space:read"],
    },
    create: {
      id: "permissions.description.media.create",
      defaultMessage: "upload new files and folders",
      required_permissions: ["space:read", "media:read"],
    },
    delete: {
      id: "permissions.description.media.delete",
      defaultMessage: "delete files and folders",
      required_permissions: ["space:read", "media:read"],
    },
    update: {
      id: "permissions.description.media.update",
      defaultMessage: "edit and move files and folders",
      required_permissions: ["space:read", "media:read"],
    },
    schedule: {
      id: "permissions.description.media.schedule",
      defaultMessage: "set availability and expiry for files",
      required_permissions: ["space:read", "media:read"],
    },
    share: {
      id: "permissions.description.media.share",
      defaultMessage: "share files with other spaces",
      required_permissions: ["space:read", "media:read"],
    },
  },
  org: {
    audit: {
      id: "permissions.description.org.audit",
      defaultMessage: "view the organization’s audit logs",
    },
    brand: {
      id: "permissions.description.org.brand",
      defaultMessage: "set organization’s default content",
    },
    features: {
      id: "permissions.description.org.features",
      defaultMessage: "turn features on and off for the organization",
    },
    update: {
      id: "permissions.description.org.update",
      defaultMessage: "edit the organization’s name",
    },
    permissions: {
      id: "permissions.description.org.permissions",
      defaultMessage: "set default org-wide permissions for all members",
    },
  },
  permission_sets: {
    read: {
      id: "permissions.description.permission_sets.read",
      defaultMessage: "see list of roles",
    },
    manage: {
      id: "permissions.description.permission_sets.manage",
      defaultMessage: "create, edit, and remove roles",
      required_permissions: ["permission_sets:read"],
    },
    import: {
      id: "permissions.description.permission_sets.import",
      defaultMessage: "import roles from ScreenCloud",
      required_permissions: ["permission_sets:read"],
    },
  },
  playback_log: {
    read: {
      id: "permissions.description.playback_log.read",
      defaultMessage: "view the organization’s playback log",
      required_permissions: ["space:read"],
    },
  },
  playlist: {
    read: {
      id: "permissions.description.playlist.read",
      defaultMessage: "see list of created playlists",
      required_permissions: ["space:read"],
    },
    create: {
      id: "permissions.description.playlist.create",
      defaultMessage: "create new playlists",
      required_permissions: ["space:read", "playlist:read"],
    },
    delete: {
      id: "permissions.description.playlist.delete",
      defaultMessage: "delete playlists",
      required_permissions: ["space:read", "playlist:read"],
    },
    update: {
      id: "permissions.description.playlist.update",
      defaultMessage: "update playlists’ information and content",
      required_permissions: ["space:read", "playlist:read"],
    },
    publish: {
      id: "permissions.description.playlist.publish",
      defaultMessage: "publish new edits made to playlists",
      required_permissions: ["space:read", "playlist:read"],
    },
    share: {
      id: "permissions.description.playlist.share",
      defaultMessage: "share playlists with other spaces",
      required_permissions: ["space:read", "playlist:read"],
    },
  },
  screen: {
    read: {
      id: "permissions.description.screen.read",
      defaultMessage: "see list of paired screens",
      required_permissions: ["space:read"],
    },
    create: {
      id: "permissions.description.screen.create",
      defaultMessage: "pair new screens",
      required_permissions: ["space:read", "screen:read", "screen:content"],
    },
    delete: {
      id: "permissions.description.screen.delete",
      defaultMessage: "delete screens",
      required_permissions: ["space:read", "screen:read"],
    },
    update: {
      id: "permissions.description.screen.update",
      defaultMessage: "update screens’ names, refresh, activate and deactivate",
      required_permissions: ["space:read", "screen:read"],
    },
    cast: {
      id: "permissions.description.screen.cast",
      defaultMessage: "cast content to screens",
      required_permissions: ["space:read", "screen:read"],
    },
    content: {
      id: "permissions.description.screen.content",
      defaultMessage: "set content to screens",
      required_permissions: ["space:read", "screen:read"],
    },
    groups: {
      id: "permissions.description.screen.groups",
      defaultMessage: "create, rename, and deleting screen groups",
      required_permissions: ["space:read", "screen:read"],
    },
    log: {
      id: "permissions.description.screen.log",
      defaultMessage: "see screens’ logs",
      required_permissions: ["space:read", "screen:read"],
    },
  },
  space: {
    read: {
      id: "permissions.description.space.read",
      defaultMessage: "see list of spaces in the organization",
    },
    create: {
      id: "permissions.description.space.create",
      defaultMessage: "create new spaces",
      required_permissions: ["space:read"],
    },
    delete: {
      id: "permissions.description.space.delete",
      defaultMessage: "delete spaces",
      required_permissions: ["space:read"],
    },
    update: {
      id: "permissions.description.space.update",
      defaultMessage:
        "update spaces’ names and manage space wide operating hours",
      required_permissions: ["space:read"],
    },
    permissions: {
      id: "permissions.description.space.permissions",
      defaultMessage: "add groups to spaces and assign roles",
      required_permissions: ["space:read"],
    },
  },
  tag: {
    read: {
      id: "permissions.description.tag.read",
      defaultMessage: "see tags for screens and content",
    },
    create: {
      id: "permissions.description.tag.create",
      defaultMessage: "add tags to screens and content",
    },
    delete: {
      id: "permissions.description.tag.delete",
      defaultMessage: "delete tags from screens and content",
    },
    update: {
      id: "permissions.description.tag.update",
      defaultMessage: "edit existing tags",
    },
  },
  theme: {
    read: {
      id: "permissions.description.theme.read",
      defaultMessage: "see list of created themes",
      required_permissions: ["space:read"],
    },
    create: {
      id: "permissions.description.theme.create",
      defaultMessage: "create new themes",
      required_permissions: ["space:read"],
    },
    delete: {
      id: "permissions.description.theme.delete",
      defaultMessage: "delete themes",
      required_permissions: ["space:read"],
    },
    update: {
      id: "permissions.description.theme.update",
      defaultMessage: "update names, colors, and logos",
      required_permissions: ["space:read"],
    },
    publish: {
      id: "permissions.description.theme.publish",
      defaultMessage: "publish new edits made to themes",
      required_permissions: ["space:read"],
    },
  },
  token: {
    read: {
      id: "permissions.description.token.read",
      defaultMessage: "see list of created API tokens",
    },
    create: {
      id: "permissions.description.token.create",
      defaultMessage: "create new API tokens",
    },
    delete: {
      id: "permissions.description.token.delete",
      defaultMessage: "delete API tokens",
    },
    update: {
      id: "permissions.description.token.update",
      defaultMessage: "update permissions and info for existing API tokens",
    },
  },
  user: {
    read: {
      id: "permissions.description.user.read",
      defaultMessage: "see list of members in the organization",
    },
    invite: {
      id: "permissions.description.user.invite",
      defaultMessage: "invite new members",
      required_permissions: ["user:read", "group:members"],
    },
    manage: {
      id: "permissions.description.user.manage",
      defaultMessage: "delete members",
      required_permissions: ["user:read"],
    },
  },
  site: {
    read: {
      id: "permissions.description.site.read",
      defaultMessage: "see list of created sites",
    },
    create: {
      id: "permissions.description.site.create",
      defaultMessage: "create new sites",
      required_permissions: ["site:read"],
    },
    delete: {
      id: "permissions.description.site.delete",
      defaultMessage: "delete sites",
      required_permissions: ["site:read"],
    },
    update: {
      id: "permissions.description.site.update",
      defaultMessage: "update existing sites",
      required_permissions: ["site:read"],
    },
    share: {
      id: "permissions.description.site.share",
      defaultMessage: "share sites with other spaces",
      required_permissions: ["site:read"],
    },
  },
  white_label: {
    read: {
      id: "permissions.description.white_label.read",
      defaultMessage: "see white label settings",
    },
    update: {
      id: "permissions.description.white_label.update",
      defaultMessage: "update and reset white label settings",
      required_permissions: ["white_label:read"],
    },
  },
};

export const PAYPAL_TOKEN = "paypaltoken";
export const DIRECTDEBIT_TOKEN = "directdebit_id";

export const TOTAL_MEDIA_COLUMN = 8;

export const NEW_PLAYER_PREVIEW_WHITELIST = [
  "85e3b25e-6ec8-4744-9449-be2314556870",
  "7c900b9b-9248-41eb-9091-f9592050ba68",
  "53e551c9-6339-4e60-9643-2018d45be659",
  "d5398bc2-b08a-4acc-b856-57e3241a49df",
  "fc8f98a9-0640-489d-87ec-241a2076f15f",
  "f1711d34-f004-4ca1-b1a5-56120538d4a6",
  "1ced8fdd-9e18-4741-b551-ca85160a3a88",
];

export const DASHBOARDS_DEFAULT_KEEP_ALIVE = "45m";

export const FIRST_FETCH_ITEMS = 30;
export const MINIMUM_FETCH_MORE_ITEMS = 50;
export const MAXIMUM_FETCH_MORE_ITEMS = 100;

export const customScreenLocationKey = [
  "sc_latitude",
  "sc_longitude",
  "sc_country",
  "sc_address",
  "sc_locality",
  "sc_utc_offset",
];

export const TouchKey = ["sc_menuEnabled"];

export const DEFAULT_FONT = {
  family: "Lato",
  url: "https://fonts.googleapis.com/css?family=Lato",
};

export const MIN_DURATION_CONTENT = 1000;

export const EMPTY_VOID_FUNCTION = () => {};

export enum AppNames {
  Youtube = "YouTube",
}

export enum CANVAS_ROUTE {
  CANVAS = "/canvas",
  CANVAS_TEMPLATE = "/canvas-templates",
  CANVAS_TEMPLATE_GALLERY = "/canvas-template-gallery",
}

export const TAG_LIMIT = 3;
export const SCREEN_TAG_LIMIT = 10;

export const USER_JOB_FUNCTIONS = [
  "Administrative",
  "Data & Analytics",
  "Compliance",
  "Executive Board",
  "Finance",
  "HR",
  "Internal Comms",
  "IT",
  "Marketing",
  "Operations",
  "Sales",
  "Other",
];

export const SECURE_MEDIA_API_PATH = "/auth";
export const DEBOUNCE_TIMEOUT_MS = 500;
export const DEBOUNCE_TIMEOUT_SHORT_MS = 200;

export const UNKNOWN_PLATFORM = "Unknown Platform";
export const UNKNOWN_MODEL = "Unknown Model";
export const UNKNOWN_DEVICE = "Unknown Device";

export const YEAR_MS = 31556952000;
export const MONTH_MS = 2629800000;
export const DAY_MS = 86400000;
export const HOUR_MS = 3600000;
export const MIN_MS = 60000;
export const SEC_MS = 1000;

export const FROM_DETAIL_PAGE_QUERYSTRING = "from_detail";
export const FROM_CHANNEL_PAGE_QUERYSTRING = "from_channel";

export const EMAIL_DOMAIN_LIST = [
  "gmail.com",
  "live.com",
  "outlook.com",
  "yahoo.com",
  "mail.com",
  "inbox.com",
  "hotmail.com",
];

export const SUBSCRIPTION_STATUS: Record<string, SubscriptionStatus> = {
  IN_TRIAL: "in_trial",
  PAUSED: "paused",
};

export const BILLING_PROVIDER_STORAGE_KEY = "billingProvider";

export enum COLUMNS_ENUM {
  ScreenStatus = "screen_status",
  PlayerWarning = "player_warning",
  ConnectedAt = "connected_at",
  NowPlaying = "now_playing",
  ScreenGroup = "screen_group",
  SpaceName = "space_name",
  DeviceModel = "device_model",
  DevicePlatform = "device_platform",
  ScreenId = "screen_id",
  MacAddress = "mac_address",
  IpAddress = "ip_address",
}

export const DEFAULT_COLUMNS_VISIBLE = [
  COLUMNS_ENUM.ScreenStatus,
  COLUMNS_ENUM.PlayerWarning,
  COLUMNS_ENUM.ConnectedAt,
  COLUMNS_ENUM.NowPlaying,
  COLUMNS_ENUM.ScreenGroup,
  COLUMNS_ENUM.SpaceName,
  COLUMNS_ENUM.DeviceModel,
  COLUMNS_ENUM.DevicePlatform,
  COLUMNS_ENUM.ScreenId,
];

export const DEFAULT_COLUMNS_HIDDEN = [
  COLUMNS_ENUM.IpAddress,
  COLUMNS_ENUM.MacAddress,
];

/**
 * List of industries
 */
export enum Industries {
  Agriculture = "Agriculture",
  Education = "Education",
  Energy = "Energy",
  Events = "Events",
  Finance = "Finance",
  Fitness = "Fitness",
  Healthcare = "Healthcare",
  HospitalityFoodBeverage = "Hospitality/Food Beverage",
  Manufacturing = "Manufacturing",
  MediaAndAdvertising = "Media & Advertising",
  PlacesOfWorship = "Places of worship",
  RealEstate = "Real Estate",
  Retail = "Retail",
  SoftwareAndService = "Software & Services",
  Telecommunication = "Telecommunication",
  Transportation = "Transportation",
  Other = "Other",
}

export const DEFAULT_PAGE_LIMIT = 10;

export const STUDIO_ROUTES = {
  SCREEN_PAIRING_ONBOARDING: "/screens/pairing-onboarding",
  CHANNEL_DESIGN_ONBOARDING: "/channels/design-onboarding",
} as const;

export enum PLATFORM_ENUM {
  ALL_PLATFORMS = "all-platforms",
  UNKNOWN_PLATFORM = "unknown-platform",
  SCREENCLOUDOS = "screencloudos",
  AMAZON = "amazon",
  ANDROID = "android",
  WINDOWS = "windows",
  OSX = "osx",
  CHROMEOS = "chromeos",
  BRIGHTSIGN = "brightsign",
  LGWEBOS = "lgwebos",
  SAMSUNGSSP = "samsungssp",
  IOS = "ios",
  WEB = "web",
  BROWSER = "browser",
  LINUX = "linux",
  CAST = "cast",
  OTHER = "other",
  PIXI = "pixi",
  ZOOM = "zoom",
  MTR = "mtr",
}

export const PLATFORM_DISPLAY_NAME = {
  "all-platforms": "All Platforms",
  "unknown-platform": "Unknown Platform",
  screencloudos: "ScreenCloud OS",
  amazon: "Amazon Fire OS",
  android: "Android",
  windows: "Windows",
  osx: "macOS",
  chromeos: "ChromeOS",
  brightsign: "BrightSign",
  lgwebos: "LG WebOS",
  samsungssp: "Samsung Tizen",
  ios: "iOS",
  web: "Web",
  browser: "Browser",
  linux: "Linux",
  cast: "Cast",
  other: "Other",
  pixi: "PIXI",
  zoom: "Zoom Rooms",
  mtr: "Microsoft Teams Rooms",
};

export const PLATFORM_LIST = [
  PLATFORM_ENUM.SCREENCLOUDOS,
  PLATFORM_ENUM.AMAZON,
  PLATFORM_ENUM.ANDROID,
  PLATFORM_ENUM.WINDOWS,
  PLATFORM_ENUM.OSX,
  PLATFORM_ENUM.CHROMEOS,
  PLATFORM_ENUM.BRIGHTSIGN,
  PLATFORM_ENUM.LGWEBOS,
  PLATFORM_ENUM.SAMSUNGSSP,
  PLATFORM_ENUM.IOS,
  PLATFORM_ENUM.WEB,
  PLATFORM_ENUM.BROWSER,
  PLATFORM_ENUM.LINUX,
  PLATFORM_ENUM.CAST,
  PLATFORM_ENUM.OTHER,
  PLATFORM_ENUM.PIXI,
  PLATFORM_ENUM.ZOOM,
  PLATFORM_ENUM.MTR,
  PLATFORM_ENUM.ALL_PLATFORMS,
];

export type PlatformType = (typeof PLATFORM_LIST)[number];

export enum ScreenGroupOrderBy {
  GroupAsc = "GROUP_ASC",
  GroupDesc = "GROUP_DESC",
}

export type CustomScreenOrderBy = ScreensOrderBy | ScreenGroupOrderBy;
