import {
  Loader,
  LoaderBar,
  ModalSize,
} from "@screencloud/screencloud-ui-components";
import * as React from "react";
import TagManager from "react-gtm-module";
import {
  PairScreenMutationVariables,
  ScreenGroup,
  ScreenListDocument,
} from "src/types.g";
import ScreenPairing from "src/components/ScreenPairing";
import { ApolloProps, withData } from "./apollo";

import { Redirect } from "react-router-dom";
import { appConfig } from "src/appConfig";
import BillingMessage, {
  BillingMessageActions,
  BillingMessageActionsEnum,
} from "src/pages/Screens/BillingMessage";
import { ROUTES } from "src/constants/routes";
import { StyledLoading } from "./styles";
import { FEATURE_FLAGS_ENUM } from "src/constants/featureFlag";
import { useAppContext } from "src/hooks/useAppContext";
import { FirstScreenPairingModal } from "../FirstScreenPairingModal";
import { STUDIO_ROUTES } from "src/constants/constants";
import { useLocation } from "react-router";
import { isTrialist } from "src/pages/Welcome/utils";
import { PIXI_TRIALIST_PREFERENCES_KEYS } from "src/pages/Welcome/constants";
import { trackMixpanelEvent } from "src/utils/mixpanel";

interface Props {
  mixpanelEvent?: string;
  onTrialModeChange?: (screenId: string, isTrialModeChange: boolean) => void;
  defaultPairingCode?: string;
}

const AddScreen: React.FC<Props & ApolloProps> = (
  props: Props & ApolloProps,
) => {
  const context = useAppContext();
  const location = useLocation();
  const [isTrialMode, setIsTrialMode] = React.useState(false);
  const isMounted = React.useRef(true);
  const canPairScreenData = props.canPairScreen;
  const isLoading =
    appConfig.billingEnabled && !canPairScreenData && !props.screenLicenses;
  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (!isMounted.current) {
      return;
    }
    if (location?.pathname !== STUDIO_ROUTES.SCREEN_PAIRING_ONBOARDING) {
      return;
    }

    openFirstScreenPairingModal();
  }, [location.pathname]);
  const refetchQueries = () => {
    const variable: any = {
      spaceId: context.user.settings.spaceId,
    };
    return {
      query: ScreenListDocument,
      variables: variable,
    };
  };

  const onTrialModeChange = (isTrialModeChecked: boolean) => {
    setIsTrialMode(isTrialModeChecked);
  };

  const onPairing = async (
    pairingCode: string,
    callback: (success: boolean, errorMessage?: any) => void,
    screenGroups?: ScreenGroup[],
  ) => {
    const { data } = props;
    const allScreenGroups = data?.data?.allScreenGroups;
    const screenGroupNodes = allScreenGroups?.nodes ?? screenGroups;
    const hasValidScreenGroups =
      screenGroupNodes && screenGroupNodes.length > 0;

    if (hasValidScreenGroups) {
      const screenGroupId = screenGroupNodes[0].id;
      const screenData: PairScreenMutationVariables = {
        input: {
          pairingCode,
          preview: isTrialMode,
          screenGroupId,
          spaceId: context.user.settings.spaceId,
        },
      };

      const tagManagerData = {
        dataLayer: {
          event: "studio_screen_add",
        },
      };

      props
        .pairScreen({
          refetchQueries: [refetchQueries()],
          variables: screenData,
        })
        .then(({ data }) => {
          if (data?.createScreen?.screen) {
            TagManager.dataLayer(tagManagerData);
            const pairScreenData = data.createScreen.screen;

            if (callback) {
              callback(false);
            }
            // if the screen is a pixi trialist and there is no active screen (it's the first screen) then update the org pixi trialist data
            if (props.screenLicenses.active === 0 && isTrialist(context)) {
              props.updateOrgPixiTrialistData({
                variables: {
                  input: {
                    key: PIXI_TRIALIST_PREFERENCES_KEYS.COMPLETED_PAIR_SCREEN,
                    value: true,
                  },
                },
              });
              trackMixpanelEvent({
                event: props.mixpanelEvent ?? "Screen Pairing Completed",
                context,
              });
            }
            context.modal.closeModals();
            context.history.push("/screens/" + pairScreenData.id + "?new=true");
          } else {
            callback &&
              callback(true, "ui_component.screen_pairing.pair_error");
          }
        })
        .catch((error) => {
          const errorMessage = `${error.message
            .replace(/graphql error:/gi, "")
            .trim()}`;
          if (callback) {
            callback(
              true,
              errorMessage === "UNKNOWN_ERROR"
                ? "ui_component.screen_pairing.invalid_card"
                : "ui_component.screen_pairing.pair_error",
            );
          }
        });
    }
  };

  const billingMessageCallback = (action: BillingMessageActions) => {
    switch (action) {
      case BillingMessageActionsEnum.PAYMENT:
        const path = context.hasSbbFlag()
          ? ROUTES.BILLING_MANAGEMENT
          : ROUTES.BILLING_LATEST_MANAGEMENT;
        context.modal.closeNavigationControlModal();
        context.modal.closeModals();
        context.history.push(path);
        break;
      case BillingMessageActionsEnum.RESUME:
        const accountPath = context.hasSbbFlag()
          ? ROUTES.ACCOUNT
          : ROUTES.ACCOUNT_LATEST;
        context.modal.closeNavigationControlModal();
        context.modal.closeModals();
        context.history.push(accountPath);
        break;
      case BillingMessageActionsEnum.CANCEL:
      default:
        context.modal.closeNavigationControlModal();
        context.modal.closeModals();
        break;
    }
  };

  const openFirstScreenPairingModal = () => {
    context.history.push(STUDIO_ROUTES.SCREEN_PAIRING_ONBOARDING);
    context.modal.openNavigationControlModal(
      <FirstScreenPairingModal
        onClose={() => {
          context.modal.closeNavigationControlModal();
          context.history.replace("/screens");
          openScreenPairingModal(true);
        }}
      />,
      "",
      {
        opts: {
          size: ModalSize.LARGE,
          disableTitle: true,
        },
        onClose: () => {
          context.history.replace("/screens");
          openScreenPairingModal(true);
        },
      },
    );
  };

  const openScreenPairingModal = (isOnboarding?: boolean) => {
    context.modal.openModal(
      <ScreenPairing
        onPairing={onPairing}
        onTrialModeChange={onTrialModeChange}
        openOnboardingScreenPairingModal={openFirstScreenPairingModal}
        screenLicences={props.screenLicenses}
        subscription={props.subscription}
        isDuringOnboarding={isOnboarding}
      />,
      "New Screen",
    );
  };

  if (isLoading) {
    return (
      <StyledLoading>
        <LoaderBar />
        <Loader inline="centered" size="small" active />
      </StyledLoading>
    );
  }

  if (canPairScreenData === "SUCCESS") {
    return (
      <ScreenPairing
        onPairing={onPairing}
        onTrialModeChange={onTrialModeChange}
        defaultPairingCode={props.defaultPairingCode}
        screenLicences={props.screenLicenses}
        subscription={props.subscription}
        openOnboardingScreenPairingModal={openFirstScreenPairingModal}
      />
    );
  } else if (canPairScreenData === "BILLING_SUBSCRIPTION_NOT_FOUND") {
    return <Redirect to={"/billing"} />;
  } else {
    return (
      <BillingMessage
        type={canPairScreenData}
        screenLicenses={props.screenLicenses}
        callback={billingMessageCallback}
        underMaintenance={context.shouldShowFeature(
          FEATURE_FLAGS_ENUM.BILLING_MAINTENANCE,
        )}
      />
    );
  }
};

export default withData(AddScreen) as React.ComponentType<Props>;
