import { useCallback } from "react";
import { FormattedHTMLMessage } from "react-intl";
import {
  CardsContainer,
  NewTrialEmptyScreenSectionStyled,
  Title,
} from "src/components/EmptyState/NewTrialEmptyScreenSection/styles";
import {
  PairScreenCard,
  VirtualScreenCard,
} from "src/components/EmptyState/NewTrialEmptyScreenSection/components/ContentCard";
import { VideoCard } from "src/components/EmptyState/NewTrialEmptyScreenSection/components/VideoCard";
import { useVirtualScreen } from "src/hooks/useVirtualScreen";
import { useScreenPairing } from "src/domains/screens/hooks/useScreenPairing";
import { useAppContext } from "src/hooks/useAppContext";
import { trackMixpanelEvent } from "src/utils/mixpanel";

export const NewTrialEmptyScreenSection = () => {
  const { openScreenPairing } = useScreenPairing();
  const { launchScreen } = useVirtualScreen();
  const context = useAppContext();

  const onClickVirtualScreenCard = useCallback(() => {
    launchScreen();
    trackMixpanelEvent({
      event: "Screen Pairing Virtual Screen Selected",
      context,
    });
    openScreenPairing("", "Screen Pairing Virtual Screen Paired");
  }, [openScreenPairing, launchScreen, context]);

  const onClickPairScreenCard = useCallback(() => {
    trackMixpanelEvent({
      event: "Screen Pairing Normal Screen Selected",
      context,
    });
    openScreenPairing("", "Screen Pairing Normal Screen Paired");
  }, [openScreenPairing, context]);

  return (
    <NewTrialEmptyScreenSectionStyled>
      <Title>
        <FormattedHTMLMessage
          id="new-trial-screen-empty-state.title"
          defaultMessage="<b>Okay</b>, let's get a screen up and running"
        />
      </Title>
      <CardsContainer>
        <PairScreenCard
          className="dark-card"
          onClick={() => onClickPairScreenCard()}
        />
        <VirtualScreenCard
          className="purple-card"
          onClick={onClickVirtualScreenCard}
        />
      </CardsContainer>
      <VideoCard />
    </NewTrialEmptyScreenSectionStyled>
  );
};
