import { Icon, ModalSize } from "@screencloud/screencloud-ui-components";
import {
  VideoCardStyled,
  ContentWrapper,
  Description,
  CTALink,
  VideoThumbnail,
  ThumbnailBackground,
  PlayButtonOverlay,
  PlayButtonTriangle,
} from "src/components/EmptyState/NewTrialEmptyScreenSection/components/VideoCard/styles";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { VideoModal } from "../VideoModal";
import { useAppContext } from "src/hooks/useAppContext";
import { trackMixpanelEvent } from "src/utils/mixpanel";
export const VideoCard = () => {
  const context = useAppContext();
  const handleVideoClick = () => {
    trackMixpanelEvent({
      event: "Screen Pairing Video Guide Selected",
      context,
    });
    context.modal.openModal(
      <VideoModal
        onClose={() => {
          context.modal.closeModals();
        }}
      />,
      null,
      {
        opts: {
          size: ModalSize.MEDIUM,
          disableCloseButton: true,
        },
      },
    );
  };

  return (
    <VideoCardStyled>
      <ContentWrapper>
        <Description>
          <FormattedHTMLMessage
            id="new-trial-screen-empty-state.video-card.description"
            defaultMessage="To learn more about suitable hardware, allow Tom to guide you through your options, in this short, informative video <i>(2mins)</i>"
          />
        </Description>
        <CTALink
          onClick={() => {
            window.open("https://screencloud.com/hardware", "_blank");
            trackMixpanelEvent({
              event: "Screen Pairing Hardware Options Selected",
              context,
            });
          }}
        >
          <Icon name="guide" />
          <FormattedMessage
            id="new-trial-screen-empty-state.video-card.cta"
            defaultMessage="Look at hardware options"
          />
        </CTALink>
      </ContentWrapper>

      <VideoThumbnail onClick={handleVideoClick}>
        <ThumbnailBackground />
        <PlayButtonOverlay>
          <PlayButtonTriangle />
        </PlayButtonOverlay>
      </VideoThumbnail>
    </VideoCardStyled>
  );
};
